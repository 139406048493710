import React from 'react';
import Header from '../components/header';
import store from '../../redux/store';
import LoginForm from '../components/loginForm';
import {openPopup} from '../../redux/actions/modalActions';
import '../../scss/verification.scss';

export default class VerificationPage extends React.Component {
	openLoginForm = () => {
		store.dispatch(
			openPopup(
				<>
					<LoginForm />
				</>
			)
		);
	};

	render() {
		return (
			<>
				<Header />
				<div className="verification-container">
					<h2 className="text">Uspešna potvrda maila.</h2>
					<h4 className="login" onClick={this.openLoginForm}>
						Uloguj se
					</h4>
				</div>
			</>
		);
	}
}
