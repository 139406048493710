import React from 'react';
import Header from '../components/header';
import '../../scss/home.scss';
import {
  withRouter
} from "react-router-dom";

const IframePage = (props) => {
		const {concatUrl, page, location, match} = props;
		let url = page;

		if (concatUrl) {
			const params = location.pathname.replace(match.path, "");
			url = url + params;
		}

		return (
			<>
				<Header />
				<div className="home-container">
					<iframe title="home-page" src={url} />
				</div>
			</>
		);
}

export default withRouter(IframePage);