export const OPEN_POPUP = 'OPEN_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';

export const openPopup = (component, customClassName) => ({
	type: OPEN_POPUP,
	component,
	customClassName
});

export const closePopup = () => ({
	type: CLOSE_POPUP
});
