import {Component} from "react";
import {isNull} from "../utils/utils";

export default class Scrollable extends Component {

    intervalTimer = null;

    componentDidMount()
    {
        window.addEventListener('scroll', this.listenToScroll);
    }

    componentWillUnmount()
    {
        window.removeEventListener('scroll', this.listenToScroll);
        clearInterval(this.intervalTimer);
    }

    listenToScroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrolled = winScroll / height;
        this.setState({
            belowHalfHeight: scrolled > 0.5 || winScroll > 700
        });
    };

    scrollStep = () => {
        if (window.pageYOffset < 10) {
            clearInterval(this.intervalTimer);
            this.intervalTimer = null;
        } else {
            window.scroll(0, window.pageYOffset - 100);
        }
    };

    triggerScroll = () => {
        if (isNull(this.intervalTimer)) {
            this.intervalTimer = setInterval(this.scrollStep, 10);
        }
    };
}