import {LOGIN_RESPONSE, SET_USER_PROFILE_DATA, SET_CREDENTIALS, SET_USER_ID, LOGOUT_USER, SET_TOKEN} from '../actions/userActions';

const INITIAL_STATE = {
	userId: '',
	profile: null,
	token: ''
};

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_USER_PROFILE_DATA:
			return {...state, profileData: {...action.data, userName: action.userName}};
		case LOGIN_RESPONSE:
			return {...state, ...action.response};
		case SET_CREDENTIALS:
			return {...state, credentials: action.credentials};
		case SET_USER_ID:
			return {...state, userId: action.id};
		case SET_TOKEN: 
			return {...state, token: action.token};
		case LOGOUT_USER:
			return INITIAL_STATE;
		default:
			return state;
	}
};

export default userReducer;
