import React, {Component} from 'react';
import Input from './input';
import DateField from './date';
import {isEmpty} from '../utils/utils';
import {formatDate} from '../utils/dateUtils';
import {FUTURE_DATES_ANSWER_IDS} from '../components/profile/profilePoll';

class PullInput extends Component {
	state = {
		inputValue: '',
		validationChecked:  this.props.validationChecked || false
	};

	inputValueChange = (e) => {
		this.setState(
			{
				inputValue: this.isDate() ? e : e.target.value
			},
			() => (this.isDate() ? this.onChange(formatDate(this.state.inputValue)) : this.onChange(this.state.inputValue))
		);
	};

	onChange = () => {
		const {onChange, id} = this.props;
		const answerId = (this.props.items && this.props.items[0] && this.props.items[0].id) || null;
		const {inputValue} = this.state;
		onChange && onChange(inputValue, id, answerId);
	};

	getValue = () => {
		const {inputValue} = this.state;
		return inputValue;
	};

	isDate = () => {
		const {items} = this.props;
		return items.some(({haveInputText}) => Math.abs(haveInputText) === 2 || Math.abs(haveInputText) === 3);
	};

	checkValidation = () => {
		const {inputValue} = this.state;
		if (this.props.required && isEmpty(inputValue)) {
			return {
				isValid: false,
				message: 'Ovo polje je obavezno'
			};
		}
		return this.props.checkValidation ? this.props.checkValidation() : {isValid: true};
	};

	render() {
		const {inputValue, validationChecked} = this.state;
		const {required} = this.props;
		if (this.isDate()) {
			return (
				<DateField
					required={required}
					checkValidation={false}
					value={inputValue}
					validationChecked={validationChecked}
					minDate={FUTURE_DATES_ANSWER_IDS.includes(this.props.id) && new Date()}
					maxDate={!FUTURE_DATES_ANSWER_IDS.includes(this.props.id) && new Date()}
					onChange={this.inputValueChange}
				/>
			);
		}
		return <Input {...this.props} validationChecked={validationChecked} value={inputValue} required={required} checkValidation={false} onChange={this.inputValueChange} />;
	}
}

export default PullInput;
