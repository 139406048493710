import React from 'react';
import {connect} from 'react-redux';
import {getBoxes, removeAllAnswers} from '../../../redux/actions/boxesActions';
import PageLoader from '../../basics/pageLoader';
import BoxSample from './boxSample';
import {isNull} from '../../utils/utils';

const mapStateToProps = ({userData, boxesData}, ownProps) => {
	const {profileData} = userData;
	return {
		userId: (profileData && profileData.Id) || ownProps.userId,
		boxes: boxesData.boxes,
		token: userData.token
	};
};

class BoxesTab extends React.Component {
	componentDidMount() {
		this.props.dispatch(removeAllAnswers());
		if (isNull(this.props.boxes)) {
			this.loadBoxes();
		}
	}

	componentWillReceiveProps(newProps) {
		const {dispatch} = this.props;
		if (this.props.userId !== newProps.userId) {
			dispatch(getBoxes(newProps.userId, newProps.token));
		}
	}

	loadBoxes = () => {
		const {userId, token, dispatch} = this.props;
		dispatch(getBoxes(userId, token));
	};

	render() {
		const {boxes} = this.props;
		const loading = isNull(boxes);
		return loading ? (
			<PageLoader visible={true} />
		) : (
			<div className="boxes-tab">
				{boxes.length > 0 ? (
					boxes
						.map((box) => box.Uzorci)
						.flat()
						.map((uzorak) => (
							<div key={uzorak.Id}>
								<BoxSample {...uzorak} reloadBoxes={this.loadBoxes}/>
							</div>
						))
				) : (
					<div className="no-result">Nema upitnika o uzorcima.</div>
				)}
			</div>
		);
	}
}

export default connect(mapStateToProps)(BoxesTab);
