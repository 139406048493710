import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { isEmpty,isMobile } from '../utils/utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ExampleCustomInput = React.forwardRef((props, ref) => (
		<input {...props} readOnly={true} ref={ref} />
	),
);

class DateField extends Component {
	state = {
		isValid: false,
		value: null,
		validationChecked: this.props.validationChecked || false
	};

	componentWillReceiveProps(nextProps, nextContext) {
		if (this.props.receiveProps && nextProps.validationChecked !== this.state.validationChecked) {
			this.handleOnBlur();
		}

		if (this.props.checkInitialValue && isEmpty(this.props.value) && !isEmpty(nextProps.value)) {
			this.setState({
				isValid: true
			})
		}
	}

	setFocus = (focus) => {
		this.setState({ focus: focus });
	};

	handleOnBlur = () => {
		this.setFocus(false);
		const validationObject = this.checkValidation();
		this.setState({
			isValid: validationObject.isValid,
			errorMessage: validationObject.message,
			validationChecked: true
		});
		this.props.onBlur && this.props.onBlur();
	};

	checkValidation = () => {
		if (this.props.required && isEmpty(this.getValue())) {
			return {
				isValid: false,
				message: 'Ovo polje je obavezno'
			};
		}
		return this.props.checkValidation ? this.props.checkValidation() : { isValid: true };
	};

	getValue = () => {
		const { value } = this.props.useValueFromParent ? this.props : this.state;
		return value;
	};

	onChange = (value) => {
		const { useValueFromParent, id } = this.props;
		if (useValueFromParent) {
			this.props.onChange(value, id);
		} else {
			this.setState({
				value: value
			});
		}
	};

	filterDates = () => {
		const { minDate, maxDate } = this.props;
		return (date) => {
			const min = minDate ? minDate < date : false;
			const max = maxDate ? maxDate > date : false;
			return min || max;
		};
	};

	render() {
		const { iconName, label, placeholder, id, type, hidden, disabled, useValueFromParent } = this.props;
		const { validationChecked, isValid, focus, errorMessage } = this.state;
		const className = classnames('input-wrapper', { checkbox: type === 'checkbox' }, { focus: focus }, { 'not-visible': hidden });
		const inputClassName = classnames({ error: !isValid && validationChecked }, { correct: validationChecked && isValid });
		const { value } = useValueFromParent ? this.props : this.state;
		return (
			<div className={className}>
				{label && <label className="input-label">{label}</label>}
				{iconName && (
					<div className="icon">
						<FontAwesomeIcon icon={iconName} />
					</div>
				)}
				<div className="input-field">
					<DatePicker
						dateFormat="dd/MM/yyyy"
						placeholderText={placeholder}
						onSelect={() => this.setFocus(false)}
						className={inputClassName}
						id={id}
						disabled={disabled}
						onChange={this.onChange}
						selected={value}
						onFocus={() => this.setFocus(true)}
						onBlur={this.handleOnBlur}
						showYearDropdown={true}
						maxDate={this.props.maxDate || undefined}
						minDate={this.props.minDate || undefined}
						filterDate={this.filterDates}
						scrollableYearDropdown={true}
						yearDropdownItemNumber={100}
						closeOnScroll={false}
						withPortal={isMobile()}
						customInput={isMobile() ? <ExampleCustomInput className={inputClassName}/> : null}
					/>
					{!isValid && validationChecked && <span className="error-message">{errorMessage}</span>}
				</div>
			</div>
		);
	}
}

DateField.defaultProps = {
	required: true,
	isValid: true,
	disabled: false,
	useValueFromParent: true,
	receiveProps: true,
	checkValidation: () => {
		return { isValid: true, message: '' };
	},
	hidden: false
};

export default DateField;
