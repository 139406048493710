import React, {Component} from 'react';
import classnames from 'classnames';

class ConsentCheck extends Component {
	state = {
		checked: false
	};

	changeChecked = () => this.setState({checked: !this.state.checked});

	isChecked = () => {
		return this.state.checked;
	};

	render() {
		const {checked} = this.state;
		const {text} = this.props;
		const className = classnames('checkbox-button', {active: checked});
		return (
			<div className="checkbox-group-content consent">
				<div className={className} onClick={this.changeChecked} />
				{text}
			</div>
		);
	}
}

export default ConsentCheck;
