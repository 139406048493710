import React from 'react';
import CollapseDiv from '../../basics/collapseDiv';
import Input from '../../basics/input';
import Button from '../../basics/button';
import {connect} from 'react-redux';
import {post} from '../../services/service';
import {setUserProfileData} from '../../../redux/actions/userActions';
import {showLoader, hideLoader} from '../../../redux/actions/loaderActions';
import {isEmpty, getDateFromString} from '../../utils/utils';
import DateField from '../../basics/date';
import RadioGroup from '../../basics/radio/radioGroup';
import {TELEPHONE_PREFIX} from '../../pages/registrationPage';
import {createErrorPopup} from '../errorPopup';
import {Select} from './../../basics/components';

class ProfileForm extends React.Component {
	constructor(props) {
		super(props);
		const {profileData} = props;
		this.state = this.getNewState(profileData, this.updateGender);
		this.fieldRefs = [];
	}

	componentWillReceiveProps(newProps) {
		const {profileData} = newProps;
		this.setState(this.getNewState(profileData), this.updateGender);
	}

	getNewState = (profileData) => {
		const naseljeId = (profileData && profileData.NaseljeId) || '';
		
		return {
			firstname: (profileData && profileData.Name) || '',
			lastname: (profileData && profileData.Surname) || '',
			username: (profileData && profileData.userName) || '',
			phone: (profileData && profileData.Telephone) || '',
			streetNo: (profileData && profileData.StreetNo) || '',
			gender: (profileData && profileData.Pol) || '',
			birthday: this.getDefaultDate(profileData),
			NaseljeId: this.props.cities.find((e) => e.value === naseljeId),
			formIsValid: true
		};
	};

	getDefaultDate = (profileData) => {
		if (profileData && profileData.Birthday) {
			return getDateFromString(profileData.Birthday);
		}
		return null;
	};

	onSelectChange = (selectedOption) => {
		this.changeRequestProperty('NaseljeId', selectedOption);
	};

	onDateChange = (date) => {
		this.changeRequestProperty('birthday', date);
	}

	onChange = (e) => {
		this.changeRequestProperty(e.target.id, e.target.value);
	};

	changeRequestProperty = (id, value) => {
		this.setState(
			{
				[id]: value
			},
			this.checkValidation
		);
	};

	checkValidation = () => {
		this.setState({
			formIsValid: this.isFormValid()
		});
	};

	isFormValid = () => {
		return (
			!this.props.disableForm &&
			this.fieldRefs &&
			this.fieldRefs
				.filter((f) => !!f)
				.map((f) => f.checkValidation().isValid)
				.every((f) => !!f)
		);
	};

	onClick = () => {
		if (this.state.formIsValid) {
			const {profileData, token, dispatch} = this.props;
			const request = {
				UserId: profileData.Id,
				user: {
					...profileData,
					userName: undefined,
					Name: this.state.firstname,
					Surname: this.state.lastname,
					Telephone: this.state.phone,
					StreetNo: this.state.streetNo,
					Birthday: this.getBirthday(),
					NaseljeId: this.state.NaseljeId.value,
					Pol: this.state.gender
				},
				token: token
			};
			dispatch(showLoader());
			post('/user/IzmenaKorisnika',
				request,
				() => {
					dispatch(setUserProfileData(request.user, this.state.username));
					dispatch(hideLoader());
					this.collapse && this.collapse.toggleIsOpen();
				},
				() => {
					createErrorPopup('Greska prilikom cuvanja profila');
					dispatch(hideLoader());
				}
			);
		} else {
			this.fieldRefs.filter((f) => !!f && f.handleOnBlur).forEach((f) => f.handleOnBlur());
		}
	};

	handleTelephoneChange = (e) => {
		const input = String(e.target.value);
		if (input.startsWith(TELEPHONE_PREFIX.substring(0, TELEPHONE_PREFIX.length - 1))) {
			const value = String(input).substring(TELEPHONE_PREFIX.length - 1).replace(/\s/g, '');
			if (!isNaN(value)) {	
				this.changeRequestProperty(e.target.id, value);
			}
		}
	};

	getBirthday = () => {
		const {birthday} = this.state;
		return !isEmpty(birthday) && birthday.toISOString().split('T')[0];
	};

	checkNumber = () => {
		const {phone} = this.state;
		const numberRegex = RegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$', 'g');
		if (numberRegex.test(phone) && phone.length > 5) {
			return {isValid: true};
		}
		return {isValid: false, message: phone.length < 6 ? 'Minimum 6 karaktera je potrebno' : 'Broj nije ispravan'};
	};

	render() {
		const {disableForm} = this.props;
		let i = 0;
		return (
			<div className="profile-collapse__item">
				<CollapseDiv title="Profil" ref={(ref) => (this.collapse = ref)}>
					<RadioGroup
						id="gender"
						label="Pol"
						small={true}
						disabled={true}
						selectedIndex={this.state.gender === 'Muški' ? 0 : 1}
						useSelectedIndexFromProps={true}
						ref={(ref) => (this.fieldRefs[i++] = ref)}
						items={[{text: 'Muški'}, {text: 'Ženski'}]}
					/>
					<Input ref={(ref) => (this.fieldRefs[i++] = ref)} id="username" label="Korisnički nalog" disabled value={this.state.username} />
					<Input
						ref={(ref) => (this.fieldRefs[i++] = ref)}
						id="firstname"
						label="Ime"
						disabled={disableForm}
						onChange={this.onChange}
						value={this.state.firstname}
					/>
					<Input
						ref={(ref) => (this.fieldRefs[i++] = ref)}
						id="lastname"
						label="Prezime"
						disabled={disableForm}
						onChange={this.onChange}
						value={this.state.lastname}
					/>
					<Input
						ref={(ref) => (this.fieldRefs[i++] = ref)}
						id="phone"
						disabled={disableForm}
						placeholder="Telefon"
						label="Telefon"
						checkValidation={this.checkNumber}
						value={TELEPHONE_PREFIX + this.state.phone}
						onChange={this.handleTelephoneChange}
					/>
					<Input
						ref={(ref) => (this.fieldRefs[i++] = ref)}
						id="streetNo"
						label="Ulica i broj"
						disabled={disableForm}
						onChange={this.onChange}
						value={this.state.streetNo}
					/>
					<DateField
						ref={(ref) => (this.fieldRefs[i++] = ref)}
						id="birthday"
						label="Datum rođenja"
						disabled={disableForm}
						value={this.state.birthday}
						maxDate={new Date()}
						onChange={this.onDateChange}
					/>
					<Select
						id="NaseljeId"
						ref={(ref) => (this.fieldRefs[i++] = ref)}
						placeholder="Naselje"
						noOptionsMessage="Nema rezultata"
						options={this.props.cities}
						onChange={this.onSelectChange}
						selectedOption={this.state.NaseljeId}
					/>
					<Button isDisabled={!this.isFormValid()} label="Sačuvaj profil" onClick={this.onClick} />
				</CollapseDiv>
			</div>
		);
	}
}

const mapStateToProps = ({userData, cityData}) => {
	return {
		profileData: userData.profileData,
		credentials: userData.credentials,
		token: userData.token,
		cities: cityData.cities.map(({id, naziv, zipcode}) => ({value: id, label: naziv + ' ' + zipcode}))
	};
};

export default connect(mapStateToProps)(ProfileForm);
