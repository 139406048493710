export const SHOW_KID_CONSENT = 'SHOW_KID_CONSENT';
export const HIDE_KID_CONSENT = 'HIDE_KID_CONSENT';
export const SHOW_ADDITIONAL_QUESTIONS = 'SHOW_ADDITIONAL_QUESTIONS';

export const showKidsConsent = () => ({
	type: SHOW_KID_CONSENT
});

export const hideKidsConsent = () => ({
	type: HIDE_KID_CONSENT
});

export const setShowAdditionalQuestions = (showAdditionalQuestions) => ({
	type: SHOW_ADDITIONAL_QUESTIONS,
	showAdditionalQuestions
})