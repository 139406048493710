export default class Routes {
	static ROOT = '/';
	static LOGIN = '/login';
	static REGISTRATION = '/registration';
	static PROFILE = '/profile';
	static VERIFY = '/verifikacija';
	static CONFIRM = '/potvrda';
	static CONSENT = '/pravila';
	static TERMS= '/pravila-i-uslovi';
	static PRIVACY= '/privatnost';
	static PRODUCTS= '/products';
	static BLOG= '/blog';
	static PARTNERS= '/partners';
	static ENGLISH= '/english';
	static REDIRECT = '/redirect';

}
