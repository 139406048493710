import axios from 'axios';
import {createErrorPopup} from '../components/errorPopup';
import { isNull } from '../utils/utils';
import store from "../../redux/store";
import {hideLoader} from "../../redux/actions/loaderActions";
import { redirectTo, removeUserData } from '../utils/appUtils';
import Routes from '../../utils/routes';
import { logoutuser } from '../../redux/actions/userActions';
import { setCookie, COOCKIE_USER_DATA } from '../utils/coockieUtils';

export const getUrl = (url) => {
	return `https://didiapi.azurewebsites.net/api${url}`;
};

export const POST_FILE_HEADERS = {
	headers: {
		'Content-Type': 'multipart/form-data'
	}
};

export const POST_IMAGE_HEADERS = {
	headers: {
		'Content-Type': 'multipart/form-data'
	},
	responseType: 'blob'
};

export const APPLICATION_JSON = {
	header: {
		'Content-Type': 'application/json'
	}
};

export const get = (url, responseCallback, errorCallback) => {
	axios
		.get(getUrl(url))
		.then((response) => responseCallback && checkStatusCode(errorCallback, response) && responseCallback(response.data))
		.catch((error) => showErrorMessage(error, errorCallback));
};

export const post = (url, request, responseCallback, errorCallback) => {
	axios
		.post(getUrl(url), request)
		.then((response) => responseCallback && checkStatusCode(errorCallback, response) && responseCallback(response.data))
		.catch((error) => showErrorMessage(error, errorCallback));
};

export const put = (url, request, responseCallback, errorCallback) => {
	axios
		.put(getUrl(url), request)
		.then((response) => {
			responseCallback && checkStatusCode(errorCallback, response) && responseCallback(response.data)
		})
		.catch((error) => showErrorMessage(error, errorCallback));
};

export const delete_ = (url, request, responseCallback, errorCallback) => {
	axios
		.delete(getUrl(url), request)
		.then((response) => responseCallback && checkStatusCode(errorCallback, response) && responseCallback(response.data))
		.catch((error) => showErrorMessage(error, errorCallback));
};

export const postFile = (url, name, file, headers, responseCallback, errorCallback) => {
	let request = new FormData();
	request.append(name, file);
	axios
		.post(getUrl(url), request, {...headers})
		.then((response) => {
			responseCallback && responseCallback(response.data);
		})
		.catch((error) => showErrorMessage(error, errorCallback));
};

const checkStatusCode = (errorCallback, response) => {
	if(response && String(response.status).startsWith("2")) {
		return checkResponseError(response);
	} else {
		console.log(response);
		errorCallback(response);
	}
};

const checkResponseError = ({data}) => {
	if (data.error && data.error.Text) {
		switch (data.error.Text) {
			case "Bad token":
			case "Login":
				removeUserData();
				setCookie(COOCKIE_USER_DATA, '');
				store.dispatch(logoutuser());
				redirectTo(Routes.LOGIN + Routes.PROFILE);
				break;
			default:
				console.log('Pogreska error poruka' + JSON.stringify(data));
		}
		return;
	}
	return true;
}

const showErrorMessage = ({response}, errorCallback) => {
	if (response && !isNull(response.data)) {
		errorCallback ? errorCallback(response.data) : createErrorPopup(response.data);
	} else {
		store.dispatch(hideLoader());
	}
};
