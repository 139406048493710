import {get} from '../../react/services/service';

export const STORE_CITIES = 'STORE_CITIES';

export const storeCities = () => {
	return (dispatch) => {
		get(
			'/user/GetNaselja',
			(response) => {
				dispatch(setCities(response));
			},
			(error) => {
				console.log(error);
			}
		);
	};
};

export const setCities = (cities) => ({
	type: STORE_CITIES,
	cities
});
