export const isNull = (value) => value === undefined || value === null;

export const isEmpty = (value) => isNull(value) || value === '';

export const isDate = (date) => {
    return date instanceof Date && isFinite(date);
};

export const getDateFromString = (string) => {
    const date = new Date();
    const day =  string.slice(8,10);
    const month =  string.slice(5,7);
    const year = string.slice(0,4);
    date.setMonth(month - 1);
    date.setFullYear(year);
    date.setDate(day);
    return date;
};

export const isListEmpty = (list) => {
    return isNull(list) || list.length === 0;
};

export const isMobile = () => {
    return window.navigator.userAgent.toLowerCase().includes("mobi");
};

export const copyToClipboard = (text) => {
    var textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
}

export const toCamel = (object) => {
    let newObject, newKey, value;
    if (object instanceof Array) {
      return object.map((value) => {
          if (typeof value === "object") {
            value = toCamel(value)
          }
          return value
      })
    } else {
        newObject = {}
        Object.keys(object).forEach(key => {
            newKey = (key.charAt(0).toLowerCase() + key.slice(1) || key).toString()
            value = object[key]
            if (value instanceof Array || (!isNull(value) && value.constructor === Object)) {
                value = toCamel(value)
            }
            newObject[newKey] = value
        })
    }
    return newObject
  }