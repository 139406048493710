import {HIDE_KID_CONSENT, SHOW_KID_CONSENT, SHOW_ADDITIONAL_QUESTIONS} from "../actions/consentActions";

const consentReducer = (
    state = {
        visible: false
    },
    action
) => {
    switch (action.type) {
        case SHOW_KID_CONSENT:
            return {...state, visible: true};
        case HIDE_KID_CONSENT:
            return {...state, visible: false};
        case SHOW_ADDITIONAL_QUESTIONS: 
            return {...state, showAdditionalQuestions: action.showAdditionalQuestions}
        default:
            return state;
    }
};

export default consentReducer;
