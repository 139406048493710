import React from "react";
import {
  withRouter
} from "react-router-dom";
import LoginForm from "../components/loginForm";
import store from "../../redux/store";
import { openPopup } from "../../redux/actions/modalActions";
import Header from '../components/header';
import Routes from "../../utils/routes";

class LoginPage extends React.Component {

    componentDidMount() {
        this.openLoginForm();
    }

    openLoginForm = () => {
        const redirectUrl = this.props.location.pathname.replace(Routes.LOGIN, "")

		store.dispatch(
			openPopup(
				<>
                    <LoginForm redirectUrl={redirectUrl} />
				</>
			)
		);
	};

    render() {
        return <div><Header /></div>
    }
}

export default withRouter(LoginPage);