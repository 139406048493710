import React from 'react';
import {Button, DateField} from '../components';
import RadioGroup from '../radio/radioGroup';
import {getDateNYearsInPast} from '../../utils/appUtils';
import {createErrorPopup} from '../../components/errorPopup';
import {formatToIsoString} from '../../utils/dateUtils';

const GENDERS = [{text: 'Muski'}, {text: 'Zenski'}];
const randomGender = () => Math.floor(Math.random() * 2);

const getKey = () =>
	Math.random()
		.toString(36)
		.substr(2, 9);

export default class KidQuestion extends React.Component {
	state = {
		kids: [
			{
				dob: null,
				gender: randomGender(),
				index: getKey()
			}
		]
	};

	dobFields = {};
	genderFields = {};

	addKid = () => {
		const {kids} = this.state;
		kids.push({
			dob: null,
			gender: randomGender(),
			index: getKey()
		});
		this.setState({
			kids
		});
	};

	removeKid = (index) => {
		const {kids} = this.state;
		if (kids.length > 1) {
			kids.splice(index, 1);
			this.setState({
				kids: kids
			});
		} else {
			createErrorPopup('Morate imati bar jedno dete!');
		}
	};

	getValue = () => {
		return this.state.kids.map((kid, index) => {
			return {
				KidNo: index + 1,
				Birthday: formatToIsoString(kid.dob),
				Pol: kid.gender + 1
			};
		});
	};

	checkValidation = () => {
		const check = (fields) =>
			Object.values(fields)
				.filter((f) => !!f)
				.every((f) => f.checkValidation().isValid);
		const isValid = check(this.genderFields) && check(this.dobFields);
		return {
			isValid: isValid
		};
	};

	handleChange = (index, value, id) => {
		const {kids} = this.state;
		kids[index][id] = value;
		this.setState({
			kids: kids
		});
	};

	render() {
		const {kids, validationChecked} = this.state;
		// NE RADI ZBOG KEY-A
		return (
			<div className="kid-question">
				{kids.map((kid, index) => (
					<div key={kid.index} className="kid-question__kid">
						<span className="kid-question__number">{index + 1}.</span>
						<RadioGroup
							id="gender"
							ref={(ref) => (this.genderFields[index] = ref)}
							onChange={(questionOrder, {text}) =>
								this.handleChange(
									index,
									GENDERS.findIndex((a) => a.text === text),
									'gender'
								)
							}
							small={true}
							useSelectedIndexFromProps={false}
							placeholder="Pol"
							items={GENDERS}
							validationChecked={validationChecked}
						/>
						<DateField
							id="dob"
							ref={(ref) => (this.dobFields[index] = ref)}
							placeholder="Datum rodjenja"
							value={kid.dob}
							useValueFromParent={true}
							onChange={(date) => this.handleChange(index, date, 'dob')}
							minDate={getDateNYearsInPast(12)}
							maxDate={new Date()}
							validationChecked={validationChecked}
						/>
						<Button className="remove-kid" onClick={() => this.removeKid(index)}>
							-
						</Button>
					</div>
				))}
				<Button className="add-kid" onClick={this.addKid}>
					+
				</Button>
			</div>
		);
	}
}
