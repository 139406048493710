import {post} from '../../react/services/service';
import {hideLoader, showLoader} from './loaderActions';
import {closePopup} from './modalActions';

export const SET_USER_ID = 'SET_USER_ID';
export const SET_TOKEN = "SET_TOKEN";
export const SET_USER_PROFILE_DATA = 'SET_USER_PROFILE_DATA';
export const SET_CREDENTIALS = 'SET_CREDENTIALS';
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const UPLOAD_AVATAR = 'UPLOAD_AVATAR';

export const setUserId = (id) => ({type: SET_USER_ID, id});

export const setToken = (token) => ({type: SET_TOKEN, token});

export const setUserProfileData = (data, userName) => ({type: SET_USER_PROFILE_DATA, data, userName});

export const loginResponse = (response) => ({type: LOGIN_RESPONSE, response});

export const setCredentials = (credentials) => ({type: SET_CREDENTIALS, credentials});

export const logoutuser = () => ({type: LOGOUT_USER});

export const uploadAvatar = (uploadRequest) => {
	return (dispatch) => {
		const request = {
			UserId: uploadRequest.userId,
			token: uploadRequest.token
		};
		dispatch(showLoader());
		post(
			'/user/SnimiSliku',
			uploadRequest,
			() => {
				post('/user/Korisnik', request, (data) => {
					dispatch(setUserProfileData(data['DidiApi.Models.User'], data.userName));
					dispatch(hideLoader());
					dispatch(closePopup());
				});
			},
			() => {
				dispatch(hideLoader());
			}
		);
	};
};
