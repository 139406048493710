import React, {Component} from 'react';
import Loader from 'react-loader-spinner';
import {connect} from 'react-redux';

const GREEN_COLOR = '#4caf50';

const mapStateToProps = ({loaderData}) => {
	return {visible: loaderData.visible};
};

class PageLoader extends Component {
	render() {
		const {visible} = this.props;
		return (
			visible && (
				<div className="page-loader-wrapper">
					<Loader className="page-loader" type="Bars" color={GREEN_COLOR} height={70} width={150} />
				</div>
			)
		);
	}
}

export default connect(mapStateToProps)(PageLoader);
