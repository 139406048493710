import React from 'react';
import Input from '../input';
import classnames from 'classnames';
import KIDS from '../../../images/kids.png';
import FOOD from '../../../images/food.png';
import HOUSE from '../../../images/house.png';
import MAKEUP from '../../../images/makeup.png';
import {IMAGE_QUESTION_ID} from '../../components/profile/profilePoll';
import Date from '../date';
import {connect} from 'react-redux';

export const QUESTION_TO_IMAGE = {
	38: MAKEUP,
	39: FOOD,
	40: HOUSE,
	41: KIDS
};

class CheckboxGroup extends React.Component {
	state = {
		selected: this.props.selected || [],
		validationChecked: this.props.validationChecked || false,
		inputValue: ''
	};

	checkDefaultValue = () => {
		const {userAnswers, items} = this.props;
		items.forEach((item, index) => {
			if (!!userAnswers.find((ua) => ua.answerId === item.id)) {
				const isImage = !!QUESTION_TO_IMAGE[item.id];
				this.onChange(index, isImage ? item.text : null);
			}
		});
	};

	handleOnBlur = () => {
		const validationObject = this.checkValidation();
		this.setState({
			isValid: validationObject.isValid,
			errorMessage: validationObject.message,
			validationChecked: true
		});
		this.props.onBlur && this.props.onBlur();
	};

	onChange = (index, titleForSubquestions) => {
		let {questionOrder} = this.props;
		const {onChange, items, id} = this.props;
		let indexAt;

		this.setState(
			(oldState) => {
				let selected = [...oldState.selected];
				indexAt = selected.findIndex((e) => e === index);
				if (indexAt !== -1) {
					selected.splice(indexAt, 1);
				} else {
					selected.push(index);
				}
				return {
					validationChecked: true,
					selected: selected
				};
			},
			() => {
				//calculate question order
				const {selected} = this.state;
				indexAt === -1 &&
					selected.filter((elem) => elem < index).map((elem) => (questionOrder += items[elem].subQuestion ? items[elem].subQuestion.length : 0));

				const item = {...items[index]};
				if (item.haveInputText === 1) {
					item.text = '';
				}

				onChange && onChange(questionOrder, item, indexAt === -1, id, titleForSubquestions); // if indexAt is -1 then it means the item is selected, otherwise unselected
			}
		);
	};

	getValue = () => {
		const {selected, inputValue} = this.state;
		const {items} = this.props;
		return selected
			.map((selectedIndex) => {
				const item = items[selectedIndex];
				if (item.haveInputText === 1 || item.haveInputText === 2 || item.haveInputText === 3) {
					return inputValue;
				}
				return items[selectedIndex].text;
			})
			.join(',');
	};

	getErrorMessage = () => {
		const numberOfAnswers = this.props.numberOfAnswers || 1;
		const numberOfSelectedAnswers = (this.state.selected && this.state.selected.length) || 0;
		if (numberOfSelectedAnswers === 0 && numberOfAnswers === 1) {
			return `Molimo označi najmanje ${numberOfAnswers} ${numberOfAnswers === 1 ? 'odgovor' : 'odgovora'}`;
		}
		return `Molimo označi najviše ${numberOfAnswers} ${numberOfAnswers === 1 ? 'odgovor' : 'odgovora'}`;
	};

	checkValidation = () => {
		const {numberOfAnswers} = this.props;
		if (
			(numberOfAnswers && this.state.selected.length > numberOfAnswers) ||
			(this.props.required && this.state.selected.length < 1 && numberOfAnswers !== 0)
		) {
			return {
				isValid: false,
				message: this.getErrorMessage()
			};
		}

		if (this.additionalInputEmpty()) {
			return {
				isValid: false,
				message: 'Molimo, dopiši odgovor'
			};
		}
		return this.props.checkValidation ? this.props.checkValidation() : {isValid: true};
	};

	additionalInputEmpty = () => {
		const {items} = this.props;
		const {selected, inputValue} = this.state;

		if (selected.some((s) => this.isInput(items[s]) || this.isDate(items[s])) && !inputValue) {
			return true;
		}
		return false;
	};

	isInput = (item) => item && item.haveInputText === 1;

	isDate = (item) => item && (item.haveInputText === 2 || item.haveInputText === 3);

	getAdditionalInput = () => {
		const {items} = this.props;
		const {selected, inputValue} = this.state;

		if (selected.some((s) => this.isInput(items[s]))) {
			return (
				<div className="additional-input">
					<Input required={false} value={inputValue} onChange={this.inputValueChange} />
				</div>
			);
		} else if (selected.some((s) => this.isDate(items[s]))) {
			return (
				<div className="additional-input">
					<Date required={false} checkValidation={false} value={inputValue} onChange={this.dateInputValueChange} />
				</div>
			);
		}
		return null;
	};

	dateInputValueChange = (value) => {
		this.setState({
			inputValue: value
		});
	};

	inputValueChange = (e) => {
		this.setState(
			{
				inputValue: e.target.value
			},
			() => {
				const {questionOrder, items, id, onChange} = this.props;
				const index = items.findIndex((item) => item.haveInputText === 1);
				if (index > -1) {
					const item = {...items[index], text: this.state.inputValue};
					onChange && onChange(questionOrder, item, true, id);
				}
			}
		);
	};

	isCategoryDone = (id) => {
		return this.props.categories[id] === true;
	};

	render() {
		const {items, id, selected} = this.props;
		const {validationChecked} = this.state;
		const validationObject = this.checkValidation();
		const checkboxGroupClassname = classnames('checkbox-group', {'image-group': id === IMAGE_QUESTION_ID});
		return (
			<div className={checkboxGroupClassname} onClick={() => this.setState({validationChecked: true})}>
				{items.map((item, index) => {
					const isImage = !!QUESTION_TO_IMAGE[item.id];
					const isCategoryDisabled = isImage && this.isCategoryDone(item.id);
					const classes = `checkbox-button ${selected.includes(index) ? 'active' : ''}`;
					const imageClassName = classnames('image-item', {active: selected.includes(index) || isCategoryDisabled}, {disabled: isCategoryDisabled});
					return isImage ? (
						<div key={index} className="image-group-item">
							<img
								className={imageClassName}
								src={QUESTION_TO_IMAGE[item.id]}
								onClick={() => !isCategoryDisabled && this.onChange(index, item.text)}
								alt=""
							/>
							<span className="image-text">{item.text}</span>
						</div>
					) : (
						<div key={index} className="checkbox-group-item">
							<div className="checkbox-group-content" onClick={() => this.onChange(index, '')}>
								<div className={classes} />
								{item.text}
							</div>
						</div>
					);
				})}
				{this.getAdditionalInput()}
				{!validationObject.isValid && validationChecked && id !== IMAGE_QUESTION_ID && (
					<div className="error-message">{validationObject.message}</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = ({questionData}, ownProps) => {
	const {userAnswers} = questionData;
	const {id, items, useStoredIndex} = ownProps;

	if (useStoredIndex) {
		let selected = [];
		userAnswers.forEach((answer) => {
			if (answer.questionId === id) {
				const answerId = answer.AnswerId;
				selected.push(items.findIndex((item) => item.id === answerId));
			}
		});

		return {
			selected: selected
		};
	}
	return {
		selected: ownProps.selected
	};
};

export default connect(mapStateToProps, null, null, {forwardRef: true})(CheckboxGroup);

CheckboxGroup.defaultProps = {
	numberOfAnswers: 1,
	useStoredIndex: true,
	selected: []
};
