import Input from '../basics/input';
import React from 'react';
import {isEmpty} from '../utils/utils';
import {post} from '../services/service';
import HandleEnterClick from '../utils/handleEnterClick';
import NavLinkButton from '../basics/NavLinkButton';
import Routes from '../../utils/routes';
import {createErrorPopup} from "../components/errorPopup";
import PropTypes from 'prop-types';
import { faKey } from '@fortawesome/free-solid-svg-icons';

class PasswordForm extends HandleEnterClick {
	static contextTypes = {
		router: PropTypes.shape({
			history: PropTypes.shape({
				push: PropTypes.func.isRequired
			})
		})
	};

	state = {
		request: {
			password: '',
			confirmedPassword: ''
		},
		formIsValid: false
	};

	onEnterClick = () => {
		this.savePassword(() => {
			this.context.router.history.push(Routes.CONFIRM);
		});
	};

	changeRequestProperty = (id, value) => {
		this.setState(
			{
				request: {
					...this.state.request,
					[id]: value
				}
			},
			this.checkValidation
		);
	};

	checkValidation = () => {
		const {password, confirmedPassword} = this.state.request;
		this.setState({
			formIsValid: !isEmpty(password) && password === confirmedPassword
		});
	};

	isFormValid = () => {
		return this.state.formIsValid;
	};

	handleChange = (e) => {
		this.changeRequestProperty(e.target.id, e.target.value);
	};

	savePassword = (redirectCallback) => {
		const {regRequest} = this.props;
		const {password} = this.state.request;
		if (this.isFormValid()) {
			post('/user/DependNew', {
				...regRequest,
				pass: password
			}, 
			({id}) => this.successRegistration(redirectCallback, id));
		} else {
			createErrorPopup("Morate potvrditi unetu šifru");
		}
	};

	successRegistration = (redirectCallback, userId) => {
		userId && redirectCallback();
	};

	render() {
		const {request} = this.state;
		const {password, confirmedPassword} = request;
		return (
			<>
				<span className="subtitle">Izaberite lozinku za Vaš nalog</span>
				<Input type="password" id="password" label="Lozinka:" value={password} iconName={faKey} onChange={this.handleChange} />
				<Input
					type="password"
					id="confirmedPassword"
					label="Ponovite lozinku:"
					value={confirmedPassword}
					iconName={faKey}
					onChange={this.handleChange}
				/>
				<NavLinkButton className="btn" label="POTVRDI LOZINKU" to={Routes.CONFIRM} beforeChange={this.savePassword} />
			</>
		);
	}
}

export default PasswordForm;
