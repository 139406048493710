import React from 'react';
import ProfileForm from './profileForm';
import ChangePasswordForm from './changePasswordForm';
import Avatar from '../../../images/default_avatar.jpg';
import {connect} from 'react-redux';
import RemoveAccountForm from './removeAccountForm';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTwitterSquare, faFacebookSquare, faLinkedin, faPinterestSquare, faYoutubeSquare, faInstagram, faTiktok} from '@fortawesome/free-brands-svg-icons';
import {faLink} from '@fortawesome/free-solid-svg-icons';
import Button from '../../basics/button';
import classnames from 'classnames';
import {post} from '../../services/service';
import ImageInput from '../../basics/imageInput/imageInput';
import store from '../../../redux/store';
import {openPopup} from '../../../redux/actions/modalActions';
import {uploadAvatar} from '../../../redux/actions/userActions';
import { copyToClipboard } from '../../utils/utils';

const LINKS = [
	{
		url: 'https://www.facebook.com/ProbajBesplatno/',
		icon: faFacebookSquare,
		name: 'Facebook',
		id: 1
	},
	{
		url: 'https://www.instagram.com/probajbesplatno.rs/?hl=en',
		icon: faInstagram,
		name: 'Instagram',
		id: 4
	},
	// {
	// 	url: 'https://twitter.com/ProbajBesplatno',
	// 	icon: faTwitterSquare,
	// 	name: 'Twitter',
	// 	id: 2
	// },
	{
		url: 'https://www.youtube.com/channel/UCWrB2TdXVWC2nPNGiXpf7eQ',
		icon: faYoutubeSquare,
		name: 'Youtube',
		id: 3
	},
	{
		url: 'https://www.linkedin.com/company/probajbesplatno/about/',
		icon: faLinkedin,
		name: 'Linkedin',
		id: 5
	},
	{
		url: 'https://www.pinterest.com/probajbesplatno/',
		icon: faPinterestSquare,
		name: 'Pinterest',
		id: 6
	},
	{
		url: 'https://www.tiktok.com/@probajbesplatno.rs?_t=8axjIDxE1Yb&_r=1',
		icon: faTiktok,
		name: 'TikTok',
		id: 7
	}
];

const FRIEND_LINK = '/registration?friend=';

class ProfileDetails extends React.Component {
	state = {
		clicked: false,
		uploadImage: null
	};

	copyLink = () => {
		this.setState({
			clicked: true
		});
		copyToClipboard(this.getFriendLink());
		setTimeout(() => this.setState({clicked: false}), 200);
	};

	getFriendLink = () => {
		const {profileData} = this.props;
		return window.location.origin + FRIEND_LINK + profileData.userName;
	};

	sendSocialInfo = (socialId) => {
		const {profileData} = this.props;
		post('/user/Social', {
			userId: profileData.Id,
			idMreze: socialId
		});
	};

	handleImageChange = (image) => {
		this.setState({
			uploadImage: image
		});
	};

	uploadImage = () => {
		const {profileData, token} = this.props;
		const {uploadImage} = this.state;
		const userId = (profileData && profileData.Id) || 0;
		const request = {
			userId: userId,
			slika: {
				ImageSource: uploadImage.byte,
				Extenzija: uploadImage.extension
			},
			token: token
		};
		store.dispatch(uploadAvatar(request));
	};

	openUploadImagePopup = () => {
		store.dispatch(
			openPopup(
				<div className="profile-avatar-upload">
					<ImageInput onChange={this.handleImageChange} label="Dodajte ili prevuce sliku..." />
					<Button className="profile-avatar-button" onClick={this.uploadImage} label="Zapamti" />
				</div>
			)
		);
	};

	render() {
		const {className, profileData} = this.props;
		const fullName = (profileData && `${profileData.Name} ${profileData.Surname}`) || '';
		const points = (profileData && profileData.Poeni) || 0;
		const imageSrc = (profileData && profileData.ImageUrl) || Avatar;
		const linkClassName = classnames('link-text', {clicked: this.state.clicked});
		return profileData ? (
			<div className={className}>
				<div className="details-content">
					<div className="profile-basic">
						<img onClick={this.openUploadImagePopup} className="profile-avatar" src={imageSrc} alt="No content" />
						<div className="profile-name">{fullName}</div>
					</div>
					<div className="profile-collapse">
						<ProfileForm disableForm={profileData.Status === 0} />
						<ChangePasswordForm />
						<RemoveAccountForm />
						{profileData && profileData.Status === 0 && <p>Čeka se verifikacija mejla</p>}
					</div>
				</div>
				<div className="right-side">
					<div className="points">Poeni: {points}</div>
					<div className = "social-header">
						<div className = "main-header">POVEŽIMO SE</div>
						<div>(i osvoji 5 poena po društvenoj mreži)</div>
					</div>
					<div className="connect">
						{LINKS.map((l) => (
							<div key={l.name} className="social-net">
								<div className="icon-wrapper">
									<a target="_blank" href={l.url} onClick={() => this.sendSocialInfo(l.id)} rel="noopener noreferrer">
										<FontAwesomeIcon icon={l.icon} />
									</a>								
								</div>							
							</div>
						))}
					</div>
					<div className="friend">
						<div className="link-info">
							<FontAwesomeIcon icon={faLink} />
							<span className={linkClassName}>{this.getFriendLink()}</span>
							<Button onClick={this.copyLink}>Copy</Button>
						</div>
						<span>* 15 poena po prijatelju. Tvoji prijatelji moraju da otvore profil, potvrde mejl i popune registracione upitnike!</span>
					</div>
				</div>
			</div>
		) : (
			<div></div>
		);
	}
}

ProfileDetails.defaultProps = {
	username: ''
};

const mapStateToProps = ({userData}) => {
	return {profileData: userData.profileData, token: userData.token};
};

export default connect(mapStateToProps)(ProfileDetails);
