import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

export default class Button extends React.Component {
	onClick = (e) => {
		e.preventDefault();
		if (this.props.isDisabled) {
			return;
		}
		if (this.props.onClick) {
			this.props.onClick(e);
		}
	};

	getClasses = () => {
		return classnames('btn', {'btn-sm': this.props.small}, {disabled: this.props.isDisabled}, {[this.props.className]: this.props.className});
	};

	render() {
		let classes = this.getClasses();
		return (
			<button
				className={classes}
				onClick={this.onClick}
				disabled={this.props.isDisabled}
				onMouseLeave={this.props.onMouseLeave}
				onMouseOver={this.props.onMouseOver}>
				{this.props.children ? this.props.children : this.props.label}
			</button>
		);
	}
}

Button.defaultProps = {
	isDisabled: false,
	label: ''
};

Button.propTypes = {
	isDisabled: PropTypes.bool,
	label: PropTypes.string
};
