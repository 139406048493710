import {Component} from 'react';

const ENTER = 'Enter';

class HandleEnterClick extends Component {
	componentDidMount() {
		document.addEventListener('keydown', this.handleKeyPress);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyPress);
	}

	handleKeyPress = (e) => {
		if (e.key === ENTER) {
			this.onEnterClick();
		}
	};
}

export default HandleEnterClick;
